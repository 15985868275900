import ReactDOM from 'react-dom/client'
import Routing from './routes/Routing';
import React from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Routing/>
    </React.StrictMode>
)
