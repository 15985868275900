import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Provider from "../middlewares/Provider";
import PublicNav from "./Navbar";
import PublicFooter from "./Footer";
import "../style/style.css";
import "../style/mobile.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ProtectedRoute from "../middlewares/Protect";

// Lazy load components
const Home = lazy(() => import("../pages/Home"));
const About = lazy(() => import("../pages/About"));
const AboutLNS = lazy(() => import("../components/about/lokanathswami"));
const AboutISK = lazy(() => import("../components/about/iskcon"));
const CalendarMain = lazy(() => import("../pages/Calender"));
const AllFestival = lazy(() => import("../pages/AllFestival"));
const DonationPage = lazy(() => import("../pages/Donation"));
const UserDashboard = lazy(() => import("../pages/user/Dashboard"));
const AdminDashboard = lazy(() => import("../pages/admin/Dashboard"));
const Login = lazy(() => import("../pages/Login"));
const NotFound = lazy(() => import("../pages/NotFound"));

class Routing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token") ? true : false,
      userRole: "public",
    };
  }

  render() {
    return (
      <GoogleReCaptchaProvider reCaptchaKey="6LehIWwqAAAAAL3kNgxJX0ljbtipWaxbSFwAY5N8">
        <Provider>
          <BrowserRouter>
            <PublicNav />
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/about/iskcon-aravade" component={About} />
                <Route
                  path="/about/lokanath-swami-maharaj"
                  component={AboutLNS}
                />
                <Route path="/about/iskcon" component={AboutISK} />
                <Route path="/festival/Calendar" component={CalendarMain} />
                <Route path="/festival/all" component={AllFestival} />
                <Route path="/donation" component={DonationPage} />

                <ProtectedRoute
                  path="/user/dashboard"
                  component={UserDashboard}
                  roles={["user"]}
                />

                <ProtectedRoute
                  path="/admin/dashboard"
                  component={AdminDashboard}
                  roles={["user"]}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </Suspense>
            <PublicFooter />
          </BrowserRouter>
        </Provider>
      </GoogleReCaptchaProvider>
    );
  }
}

export default Routing;
