import React, { useContext } from "react";
import AuthContext from "./authContext";
import { Route, Redirect } from "react-router-dom";

class Protect extends React.Component {
  static contextType = AuthContext;

  render() {
    const { isAuthenticated, user } = this.context;
    const { component: Component, roles, ...rest } = this.props;

    
    if (isAuthenticated === null || user.role === "Anonymous") {
      return null;
    }
    if (isAuthenticated === false) {
      return <Redirect to="/login" />;
    }
    if (!roles.includes(user.user.role)) {
      return <Redirect to="/notFound" />;
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          
            <Component {...props} />
          
        }
      />
    );
  }
}

export default Protect;