import React from "react";
import AuthContext from "./authContext";

class AuthProvider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isAuthenticated : localStorage.getItem('token') ? null : false,
            user : ""
        }
    }
    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        if(localStorage.getItem('token') === null){
            this.setState({
                isAuthenticated: false
            });            
        }
        else {
            try {
                const response = await fetch('/api/validate', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = await response.json();
                if (data.user) {
                    this.setState({
                        isAuthenticated: true,
                        user: data
                    });
                } else {
                    this.setState({
                        isAuthenticated: false
                    });
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    isAuthenticated: false
                });
            }
        }
    };

    render() {
        return (
            <AuthContext.Provider value={this.state}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export default AuthProvider;